@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 400;src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff) format('woff'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.otf) format('opentype');}
@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 500;src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff) format('woff'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.otf) format('opentype');}
@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 700;src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff) format('woff'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.otf) format('opentype');}
@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 900;src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff) format('woff'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.otf) format('opentype');}
@font-face {
  font-family: 'JGaegujaengyi-Medium-KO';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2110@1.0/JGaegujaengyi-Medium-KO.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
} /* 넷연이의 2023 그림일기용 J개구쟁이 폰트 추가 */

body {
  margin: 0px;
  background-color: #ffffff;
  -webkit-tap-highlight-color: transparent;
  /* font-family: 'SD Gothic Neo', Roboto, 'Noto Sans', 'Segoe UI', 'Helvetica Neue',
    Helvetica, Arial, sans-serif; */
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}

textarea {
  /* font-family: 'SD Gothic Neo', Roboto, 'Noto Sans', 'Segoe UI', 'Helvetica Neue',
    Helvetica, Arial, sans-serif; */
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR","Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  max-width: 100%;
  resize: none;
}

.swal2-container {
  z-index: 9999 !important;
}
.toast-title {
  font-weight: 400 !important;
}
.confirm-popup {
  min-height: 200px !important;
  align-content: flex-end !important;
  padding: 30px 0px 0px 0px !important;
  width: 410px !important;
  max-width: calc(100% - 30px) !important;
}
.deny-button {
  background-color: #ddd !important;
  color: #111 !important;
  border: 1px solid #ddd !important;
  margin: 0px !important;
  width: 50% !important;
  border-radius: 0px 0px 0px 5px !important;
}
.confirm-button {
  background-color: #111 !important;
  color: #fff !important;
  border: 1px solid #111 !important;
  margin: 0px !important;
  width: 50% !important;
  border-radius: 0px 0px 5px 0px !important;
  padding: 0.625em 0.5em !important;
}
.default-popup {
  min-height: 150px !important;
  align-content: flex-end !important;
  padding: 0px 0px 0px 0px !important;
  width: 410px !important;
  max-width: calc(100% - 30px) !important;
}
.default-title {
  font-size: 16px !important;
  font-weight: normal !important;
  color: #555 !important;
  padding: 40px 25px !important;
  line-height: 24px !important;
}
.default-with-title {
  font-size: 18px !important;
  color: #111 !important;
  padding: 0px 20px !important;
  margin: 30px 0px 20px 0px !important;
}
.default-with-text {
  font-size: 13px !important;
  margin: 0px 0px 30px 0px !important;
  line-height: 22px !important;
  padding: 0px 20px !important;
  color: #111 !important;
}
.default-button {
  background-color: #111 !important;
  color: #fff !important;
  border: 1px solid #111 !important;
  margin: 0px !important;
  width: 100% !important;
  border-radius: 0px 0px 5px 5px !important;
}
.default-button:focus, .confirm-button:focus, .deny-button:focus {
  box-shadow: none !important;
}
.confirm-title {
  font-size: 18px !important;
  color: #111 !important;
  padding: 0px 20px !important;
  margin: 0px 0px 20px 0px !important;
}
.confirm-text {
  font-size: 13px !important;
  margin: 0px 0px 30px 0px !important;
  line-height: 22px !important;
  padding: 0px 20px !important;
  color: #111 !important;
}
.confirm-text > a {
  color: #111 !important;
  font-weight: 700 !important;
}
.noti-popup {
  display: flex !important;
  align-items: center !important;
  padding: 10px 14px !important;
}
.noti-title {
  font-weight: 400 !important;
  margin: 0px auto 0px 0px !important;
  padding: 0 !important;
}
.noti-icon {
  margin: 0px 14px 0px 0px !important;
}
.swal2-actions {
  margin: 0 !important;
  padding: 0 !important;
}
.noti-confirm-button {
  margin: 0 0 0 10px !important;
  background-color: transparent !important;
  min-width: 45px !important;
  border-bottom: 1px solid #fff !important;
  border-radius: 0px !important;
  outline: none !important;
  padding: 2px !important;
}
.react-switch-handle {
  height: 25px !important;
  width: 25px !important;
  top: 2.5px !important;
  left: 2px !important;
}
.popup-image {
  margin: 0px !important;
}
.popup-image-popup {
  padding: 0px !important;
}
.LinesEllipsis-ellipsis {
  font-weight: bold !important;
}
.place-indicator {
  display: flex !important;
  bottom: 20px !important;
  right: 20px !important;
  left: 20px !important;
  width: calc(100% - 40px) !important;
}
.place-indicator li {
  width: 100% !important;
  height: 3px !important;
  margin: 0px !important;
}
.place-indicator .slick-active div {
  opacity: 1 !important;
}
.session-indicator {
  bottom: -25px !important;
  display: flex !important;
  width: 100% !important;
}
.session-indicator li {
  width: 100% !important;
  height: 3px !important;
  margin: 0px !important;
}
.session-indicator .slick-active div {
  opacity: 1 !important;
}
.btn-csv {
  text-decoration: none !important;
  color: #111 !important;
}
.tent-channels-indicator {
  bottom: -17px !important;
  width: 100% !important;
}
.tent-channels-indicator li {
  width: 6px !important;
  height: 6px !important;
  margin: 0px 3px !important;
}
.tent-channels-indicator .slick-active div {
  opacity: 1 !important;
}
.invitaion-modal-dots {
  bottom: -20px !important;
  width: 100% !important;
}
.invitaion-modal-dots li {
  width: 6px !important;
  height: 6px !important;
  margin: 0px 3px !important;
}
.invitaion-modal-dots .slick-active div {
  opacity: 1 !important;
}
.member-indicator {
  bottom: 32% !important;
  width: 30px !important;
  right: 16% !important;
}
.member-indicator li {
  width: 30px !important;
  height: 35px !important;
  display: none;
}
.member-indicator .slick-active {
  display: flex !important;
}
.comment-indicator {
  bottom: -52px !important;
  width: 30px !important;
  right: calc(50% - 15px) !important;
}
.comment-indicator li {
  width: 30px !important;
  height: 35px !important;
  display: none;
}
.comment-indicator .slick-active {
  display: flex !important;
  margin: 0px !important;
  justify-content: center !important;
}
/* class="slick-arrow slick-prev slick-disabled" */
.comment-slider .slick-arrow.slick-disabled {
  background-color: #d2d2d2 !important;
}
.donghaeng-indicator {
  display: flex !important;
  bottom: 65px !important;
  right: 20px !important;
  left: 20px !important;
  width: calc(100% - 40px) !important;
}
.donghaeng-indicator li {
  width: 100% !important;
  height: 3px !important;
  margin: 0px !important;
}
.donghaeng-indicator .slick-active div {
  opacity: 1 !important;
}

@keyframes slideLeft {
  0% {
    transform: translateX(0%)
  }
  100% {
    transform: translateX(-50%)
  }
}